<div class="page-content">
    <app-header></app-header>
    <app-home></app-home>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-curriculum-vitae></app-curriculum-vitae>
    <app-projects></app-projects>
    <div class="row" style="height: 100px;"></div>
    <footer class="footer">
        <p> Copyright © <span>{{currentYear}}</span> By Daniel Garcia.</p>
    </footer>
</div>