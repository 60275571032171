export const experiencesEn = [
    {
        title: "Software Engineer",
        company: 'SDI Presence, Remote',
        period: "2015 - Present (8 years)",
        technologies: 'C#, .NET, Angular, TypeScript, JavaScript, MSSQL, Swift, Python, Azure',
        missions: [
            "Architected and sustained software projects for law enforcement agencies, directly benefiting over a thousand users.",
            "Delivered an innovative network audio streaming service, leading to nearly $250,000 in savings for Santa Ana police.",
            "Designed an iOS mobile application for fire departments, enhancing data input efficiency.",
            "Engineered a Python parser for efficient data extraction, significantly reducing manual hours.",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Mobile Engineer",
        company: 'Mavien, Remote',
        period: "2014 (5 months)",
        technologies: 'Cordova, JavaScript, Android',
        missions: [
            "Spearheaded the rapid development of a mobile dating app prototype, harnessing the Facebook API.",
            "Instituted custom libraries expediting the software creation process, enabling swift prototype readiness.",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Lead Software Engineer",
        company: 'Age of Learning, Glendale, CA',
        period: "2012 - 2014 (2 years)",
        technologies: 'ActionScript, JavaScript, iOS, Andriod, SQL',
        missions: [
            "Headed a team focused on bug fixes during a codebase refactor, ensuring high-quality software maintenance.",
            "Mentored new engineers, establishing a standard for code quality and consistency.",
            "Conceived frameworks optimizing product development cycles, cutting down on testing hours.",
        ],
        detailIsDisplayed: false,
    },
];

